import { SigninAuth } from '@tms/authorization'
import { ClipText } from '@tms/social'
import InterestService from '../services/interest-sevice'

export default function useContentInterest () {
  const auth = new SigninAuth()
  const interestService = new InterestService()
  const snackbar: any = inject('snackbar')

  /**
   * 로그인 여부 확인
   */
  const checkedLogin = async (callback: () => Promise<void>, failureCallback?: () => void) => {
    if (await auth.isSignin()) {
      callback && await callback()
    } else {
      failureCallback && await failureCallback()
    }
  }

  /**
   * 읽음 처리 (게시물)
   * @param id 읽음 처리 할 게시물 ID
   */
  const readByPost = async (id: number) => {
    await interestService.readByPost({ postSeq: id })
  }

  /**
   * 북마크 (게시물)
   * @param id 북마크 할 게시물 ID
   * @param bookmarkYn 북마크 여부
   */
  const bookmark = (id: number, bookmarkYn: string) => {
    checkedLogin(async () => {
      await interestService.bookmarkByPost({ boardSeq: id, likeYn: bookmarkYn })
    })
  }

  /**
   * 팔로우
   * @param id 팔로우 할 고유 값
   * @param followYn 팔로우 여부
   */
  const follow = (id: number, followYn: string, postSeq?: number) => {
    checkedLogin(async () => {
      await interestService.follow({ followingSeq: id, followYn, postSeq })
    })
  }

  /**
   * 공유하기
   * @param url 공유할 URL
   * @param title 공유할 제목
   */
  const share = (url: string, title: string) => {
    if (navigator.share) {
      return navigator.share({ url, title })
    } else {
      // 공유기능 지원하지 않는 경우
      ClipText.clipText(url)
      snackbar.info('URL이 복사되었습니다')
      return true
    }
  }

  /**
   * 공유하기 - 데이터 저장용
   * @param postSeq 공유한 게시물 번호
   */
  const shareByAnalytics = (postSeq: number) => {
    return interestService.share({ postSeq })
  }

  return {
    checkedLogin,
    readByPost,
    bookmark,
    follow,
    share,
    shareByAnalytics
  }
}
