import { IApiWithResponse, get, post } from '@tms/core'

import type { IBookmarkRequest, IFollowRequest, IReadRequest, IShareRequest } from '../types/request'
import type { IInterestListReponse } from '../types/response'

export default class InterestService {
  /**
   * 내 관심사 목록
   * @param req
   * @returns
   */
  async getMyInterestList (): Promise<IInterestListReponse> {
    const response: IApiWithResponse<IInterestListReponse> = await get('/my/v1/profile/interest')
    return response.data
  }

  /**
   * 게시물 읽음 처리
   * @param req
   * @returns
   */
  async readByPost (parameter: IReadRequest): Promise<void> {
    await post('/board/v1/blog/read', parameter)
  }

  /**
   * 게시물 북마크
   * @param req
   * @returns
   */
  async bookmarkByPost (parameter: IBookmarkRequest): Promise<void> {
    await post('/like/v1/blog', parameter)
  }

  /**
   * 팔로우
   * @param req
   * @returns
   */
  async follow (parameter: IFollowRequest): Promise<void> {
    await post('/follow/v1', parameter)
  }

  /**
   * 공유하기
   * @param req
   * @returns
   */
  async share (parameter: IShareRequest): Promise<void> {
    await post('/share/v1', parameter)
  }
}
